// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */
import styled from 'styled-components'
import React, { useState, useRef, useEffect } from 'react';

// Wrapper for the entire layout
const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
    font-family: 'Cooper Black', sans-serif; /* Use Cooper Black font */

    /* Landscape mode: Adjusting font size */
    @media only screen and (max-height: 575.98px) and (orientation: landscape) {
     width: 100%;
    }

    /* Portrait mode: Adjusting font size */
    @media only screen and (orientation: portrait) {
     width: 100%;
         
    }
`;

// Header style
const Header = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 2px;
  background-color: #ddc5b5;
  border-bottom: 1px solid #ccc;
`;

// Wrapper for the menu layout
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`;

// Column for the main menu
const MainMenu = styled.div`
  flex: 1;
  background-color: #ddc5b5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Column for the submenu
const SubMenu = styled.div`
  flex: 2;
  background-color: #c4e4fc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Button style for the menu items
const MenuItemButton = styled.div<{ active: boolean }>`
  background-color: #bf4453;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  &:hover {
    background-color: #89303a;
  }
  /* Show submenu when active */
  ${({ active }) =>
        active &&
        `
    background-color: #89303a;
  `}
`;

// Submenu button style
const SubMenuButton = styled.button`
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;

  &:hover {
    background-color: #1e88e5;
  }

    @media (max-width: 768px) {
      font-size: 12px;
      
  }
`;

const ServiceViewBox = () => {
    // State to manage the selected menu item
    const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
    const [hoveredMenu, setHoveredMenu] = useState<string | null>(null);

    // Refs for main menu and submenu
    const menuRef = useRef<HTMLDivElement>(null);
    const subMenuRef = useRef<HTMLDivElement>(null);

    // Submenu options for each main menu
    const subMenus: Record<string, string[]> = {
        Fighter: ['Change Name', 'Change Gang', 'Change Lucky #'],
        City: ['Mine', 'Factory', 'Shop'],
        Game: ['Game1', 'Game2', 'Game3'],
        Bonus: ['Daily Prize', 'Roll the Dice'],
    };

    // Function to handle submenu button click
    const handleSubMenuClick = (menu: string, subMenu: string) => {
        console.log(`${menu} -> ${subMenu} clicked`);
    };

    // Function to handle mouse enter on a main menu item
    const handleMouseEnter = (menu: string) => {
        setHoveredMenu(menu);

    };

    // Function to handle mouse leave from a main menu item
    const handleMouseLeave = () => {
        console.log("MOUSE LEFT")
    };

    // Handle menu item click
    const handleMenuClick = (menu: string) => {
        if (selectedMenu === menu) {
            setSelectedMenu(null); // Deselect if clicking on the already selected menu
        } else {
            setSelectedMenu(menu); // Select the new menu
        }
    };

    // Submenu content based on the selected or hovered menu
    const renderSubMenuContent = () => {
        // Show submenu based on either selectedMenu or hoveredMenu
        const menuToDisplay = selectedMenu || hoveredMenu;

        if (!menuToDisplay || !subMenus[menuToDisplay]) {
            return <p>Hello!<br></br> Make a selection to begin!</p>;
        }

        return subMenus[menuToDisplay].map((subMenu) => (
            <SubMenuButton key={subMenu} onClick={() => handleSubMenuClick(menuToDisplay, subMenu)}>
                {subMenu}
            </SubMenuButton>
        ));
    };

    // Handle clicks outside the menu
    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node) &&
            subMenuRef.current && !subMenuRef.current.contains(event.target as Node)) {
            setSelectedMenu(null); // Close the menu
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <LayoutWrapper>
            {/* Header */}
            <Header>Service</Header>

            {/* Main Menu and Submenu Area */}
            <MenuWrapper>
                {/* Main Menu */}
                <MainMenu ref={menuRef}>
                    {Object.keys(subMenus).map((menu) => (
                        <MenuItemButton
                            key={menu}
                            active={selectedMenu === menu || hoveredMenu === menu}
                            onClick={() => handleMenuClick(menu)}
                            onMouseEnter={() => handleMouseEnter(menu)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {menu}
                        </MenuItemButton>
                    ))}
                </MainMenu>

                {/* Submenu Area */}
                <SubMenu ref={subMenuRef}>
                    {renderSubMenuContent()}
                </SubMenu>
            </MenuWrapper>
        </LayoutWrapper>
    );
};

export default ServiceViewBox;
