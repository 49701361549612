
//each week brewsSold resets to 0 and the partners are listed in order of their rank
//rankings change dynamically, and the board is updated in realtime, as brewsSold numbers change

export interface Partner {
  name: string;
  largeImageUrl: string;
  largeMouseOverImageUrl: string;
  largeMouseDownImageUrl: string;
  smallImageUrl: string;
  smalleMouseOverImageUrl: string;
  smallMouseDownImageUrl: string;
  brewsSold: number;
  rank: number;
  wallet: string;//if we just send the btc to a wallet
  //token: contract address for a token we buy
  //chain: the network the token is on
  //
}
// Function to update the rank of a specific partner
export const updatePartnerRank = (partnerName: string, newRank: number): boolean => {
  const partnerToUpdate = partners.find((partner) => partner.name === partnerName);

  if (partnerToUpdate) {
    partnerToUpdate.rank = newRank;
    // Sort the partners array based on the new ranks
    partners.sort((a, b) => a.rank - b.rank);
    return true; // Rank updated successfully
  }

  return false; // Partner not found
};
const partners: Partner[] = [
  {
    name: "Bitfighter",
    largeImageUrl: "/bitfgihter_assets/partners/bf_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/bf_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 1, 
    wallet: "0x000",
  },
  {
    name: "Drip",
    largeImageUrl: "/bitfgihter_assets/partners/drip_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/drip_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 1, 
    wallet: "0x000",
  },
  {
    name: "Elephant",
    largeImageUrl: "/bitfgihter_assets/partners/elephant_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/elephant_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 2, 
    wallet: "0x000",
  },
  {
    name: "Avalanche",
    largeImageUrl: "/bitfgihter_assets/partners/avax_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/avax_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 3, 
    wallet: "0x000",
  },
  {
    name: "EMP",
    largeImageUrl: "/bitfgihter_assets/partners/emp_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/emp_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 4, 
    wallet: "0x000",
  },
  {
    name: "Sphere",
    largeImageUrl: "/bitfgihter_assets/partners/sphere_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/sphere_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 5, 
    wallet: "0x000",
  },
  {
    name: "YaBonks",
    largeImageUrl: "/bitfgihter_assets/partners/yabonks_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/yabonks_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 6, 
    wallet: "0x000",
  },
  {
    name: "CryptoLink",
    largeImageUrl: "/bitfgihter_assets/partners/cryptolink_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/cryptolink_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 7,
    wallet: "0x000",
  },
  {
    name: "ThorFi",
    largeImageUrl: "/bitfgihter_assets/partners/thorfi_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/thorfi_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 8, 
    wallet: "0x000",
  },
  {
    name: "123",
    largeImageUrl: "/bitfgihter_assets/partners/123_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/123_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 9, 
    wallet: "0x000",
  },
  {
    name: "KERC",
    largeImageUrl: "/bitfgihter_assets/partners/kerc_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/kerc_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 10, 
    wallet: "0x000",
  },
  {
    name: "Lemono",
    largeImageUrl: "/bitfgihter_assets/partners/lemono_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/lemono_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 11, 
    wallet: "0x000",
  },
  {
    name: "VaporFi",
    largeImageUrl: "/bitfgihter_assets/partners/vaporfi_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/vaporfi_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 12, 
    wallet: "0x000",
  },
  {
    name: "Foxify",
    largeImageUrl: "/bitfgihter_assets/partners/foxify_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/foxify_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 13,
    wallet: "0x000",
  },
  {
    name: "MDB",
    largeImageUrl: "/bitfgihter_assets/partners/mdb_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/mdb_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 14,
    wallet: "0x000",
  },
  {
    name: "GMX",
    largeImageUrl: "/bitfgihter_assets/partners/gmx_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/gmx_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 15,
    wallet: "0x000",
  },
  {
    name: "Trader Joe",
    largeImageUrl: "/bitfgihter_assets/partners/traderjoe_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/traderjoe_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 16,
    wallet: "0x000",
  },
  {
    name: "Kyber Swap",
    largeImageUrl: "/bitfgihter_assets/partners/kyberswap_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/kyberswap_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 17,
    wallet: "0x000",
  },
  {
    name: "Justus",
    largeImageUrl: "/bitfgihter_assets/partners/justus_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/justus_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 18,
    wallet: "0x000",
  },
  {
    name: "Drip Coach",
    largeImageUrl: "/bitfgihter_assets/partners/dripcoach_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/dripcoach_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 19,
    wallet: "0x000",
  },
  {
    name: "Down Home Crypto",
    largeImageUrl: "/bitfgihter_assets/partners/dhc_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/dhc_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 20,
    wallet: "0x81a8c6A582e8361C69fd83E26b90Fd18b581518F",
  },
  {
    name: "Collective Finance",
    largeImageUrl: "/bitfgihter_assets/partners/collectivefi_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/collectivefi_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 21,
    wallet: "0x000",
  },
  {
    name: "Stag",
    largeImageUrl: "/bitfgihter_assets/partners/stag_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/stag_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 22, 
    wallet: "0x000",
  },
  {
    name: "Meat",
    largeImageUrl: "/bitfgihter_assets/partners/meat_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/meat_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 23,
    wallet: "0x000",
  },
  {
    name: "Chikn",
    largeImageUrl: "/bitfgihter_assets/partners/chikn_large.png",
    largeMouseOverImageUrl: "/bitfgihter_assets/partners/partner2_mouseover.png",
    largeMouseDownImageUrl: "/bitfgihter_assets/partners/partner2_mousedown.png",
    smallImageUrl: "/bitfgihter_assets/partners/chikn_small.png",
    smalleMouseOverImageUrl: "/bitfgihter_assets/partners/default_large_mouseover.png",
    smallMouseDownImageUrl: "/bitfgihter_assets/partners/default_large_mousedown.png",
    brewsSold: 0,
    rank: 24,
    wallet: "0x000",
  },
];


export default partners;
