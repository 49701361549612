
// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */

export function InvalidUserPage() {
  return (
    <div>
      <h1 style={{
        color: 'aliceblue',
        paddingTop: 200
      }}>
        Invalid User
      </h1>
      <span style={{
        color: 'grey',
      }}>
        You dont have access to use this website
      </span>
    </div>
  )
}